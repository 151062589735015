import tenantService from '@/services/tenant';

// ----------------------------------------------------------------------------
// OPTIONS
// ----------------------------------------------------------------------------
export const namespaced = true;

// ----------------------------------------------------------------------------
// STATE
// ----------------------------------------------------------------------------
export const state = {
  tenants: [],
  tenantsTotal: 0,
  current: null,
  perPage: 15,
  meta: {},
  users: [],
};

// ----------------------------------------------------------------------------
// MUTATIONS
// ----------------------------------------------------------------------------
export const mutations = {
  SET_CURRENT_TENANT(state, tenantData) {
    state.current = tenantData;
  },
  SET_TENANTS(state, tenants) {
    state.tenants = tenants;
  },
  SET_TENANTS_TOTAL(state, tenantsTotal) {
    state.tenantsTotal = tenantsTotal;
  },
  SET_TENANTS_METADATA(state, tenantsMetadata) {
    state.meta = tenantsMetadata;
  },
  ADD_TENANT(state, tenant) {
    state.tenants.unshift(tenant);
  },
  ENABLE_TENANT(state) {
    state.current.is_active = true;
  },
  DISABLE_TENANT(state) {
    state.current.is_active = false;
  },
  SET_TENANT_USERS(state, users) {
    state.users = users;
  },
};

// ----------------------------------------------------------------------------
// ACTIONS
// ----------------------------------------------------------------------------
export const actions = {
  fetchTenants({ commit, state }, { page, search }) {
    return tenantService.getTenants(state.perPage, page, search).then((response) => {
      commit('SET_TENANTS', response.data.data);
      commit('SET_TENANTS_TOTAL', response.data.meta.total);
      commit('SET_TENANTS_METADATA', response.data.meta);
      return response.data.data;
    });
  },

  fetchTenant({ commit, state }, id) {
    if (state.current && state.current.id === id) {
      return state.current;
    }

    return tenantService.getTenant(id).then((response) => {
      commit('SET_CURRENT_TENANT', response.data.data);
      return response.data.data;
    });
  },

  createTenant({ commit }, data) {
    return tenantService.createTenant(data).then((response) => {
      commit('ADD_TENANT', response.data.data);
      return response.data.data;
    });
  },

  updateTenant({ commit }, { tenantId, data }) {
    return tenantService.updateTenant(tenantId, data).then((response) => {
      commit('SET_CURRENT_TENANT', response.data.data);
      return response.data.data;
    });
  },

  deleteTenant(context, tenantId) {
    return tenantService.deleteTenant(tenantId).then((response) => {
      return response.data.data;
    });
  },

  enableTenant({ commit }, tenantId) {
    return tenantService.enableTenant(tenantId).then((response) => {
      commit('SET_CURRENT_TENANT', response.data.data);
      return response.data.data;
    });
  },

  disableTenant({ commit }, tenantId) {
    return tenantService.disableTenant(tenantId).then((response) => {
      commit('SET_CURRENT_TENANT', response.data.data);
      return response.data.data;
    });
  },

  fetchUsers({ commit }, tenantId) {
    return tenantService.getTenantUsers(tenantId).then((res) => {
      commit('SET_TENANT_USERS', res.data.data);
      return res.data.data;
    });
  },

  createUser(context, { tenantId, data }) {
    return tenantService.createTenantUser(tenantId, data).then((res) => {
      return res.data.data;
    });
  },

  editUser(context, { userId, data }) {
    return tenantService.updateTenantUser(userId, data).then((res) => {
      return res.data.data;
    });
  },

  enableUser(context, data) {
    return tenantService.enableTenantUser(data).then((res) => {
      return res.data.data;
    });
  },

  disableUser(context, userId) {
    return tenantService.disableTenantUser(userId).then((res) => {
      return res.data.data;
    });
  },

  deleteUser(context, userId) {
    return tenantService.deleteTenantUser(userId).then((res) => {
      return res.data.data;
    });
  },
};

// ----------------------------------------------------------------------------
// GETTERS
// ----------------------------------------------------------------------------
