import Vue from 'vue';
import App from './App.vue';
import './index.css';
import 'nprogress/nprogress.css';
import './assets/sass/main.scss';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.filter('kb_to_gb', (value) => {
  return Math.round((value / (1024 * 1024)) * 100) / 100;
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
