<template>
  <button
    type="button"
    class="bg-primary text-white py-2 px-4 rounded-md focus:outline-none focus:ring"
    :class="{
      'bg-secondary text-gray-800': secondary,
      'opacity-75 cursor-not-allowed': disabled,
    }"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    secondary: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
};
</script>
