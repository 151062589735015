import Vue from 'vue';
import Vuex from 'vuex';
import * as auth from './modules/auth';
import * as notification from './modules/notification';
import * as project from './modules/project';
import * as tenant from './modules/tenant';
import * as user from './modules/user';
import * as admin from './modules/admin';
import * as camera from './modules/camera';
import * as photo from './modules/photo';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    notification,
    project,
    tenant,
    user,
    admin,
    camera,
    photo
  },
});
