<template>
  <div class="max-w-screen-2xl mx-auto mb-20">
    <div class="shadow-md bg-white py-2">
      <div class="max-w-screen-xl mx-auto">
        <Header /> 
      </div>

      <slot name="sub-nav"></slot>
    </div>

    <main class="max-w-screen-xl mx-auto mt-8">
      <slot></slot>
    </main>

    <toast-message></toast-message>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import ToastMessage from '@/components/ToastMessage';

export default {
  name: 'AppLayout',
  components: {
    Header,
    ToastMessage,
  },
};
</script>
