import axios from 'axios';
import config from '@/config';
import store from '@/store';

const apiClient = new axios.create({
  baseURL: config.API_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 90000,
});

const token = localStorage.getItem('token');

if (token) {
  apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch('auth/logout');
    }

    return Promise.reject(error);
  },
);

export { apiClient as default };
