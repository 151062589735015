import cameraService from '@/services/camera';

// ----------------------------------------------------------------------------
// OPTIONS
// ----------------------------------------------------------------------------
export const namespaced = true;

// ----------------------------------------------------------------------------
// STATE
// ----------------------------------------------------------------------------
export const state = {
  cameras: [],
  current: null,
};

// ----------------------------------------------------------------------------
// MUTATIONS
// ----------------------------------------------------------------------------
export const mutations = {
  SET_CURRENT_CAMERA(state, cameraData) {
    state.current = cameraData;
  },
  SET_CAMERAS(state, cameras) {
    state.cameras = cameras;
  },
  ADD_CAMERA(state, camera) {
    state.cameras.unshift(camera);
  },
};

// ----------------------------------------------------------------------------
// ACTIONS
// ----------------------------------------------------------------------------
export const actions = {
  fetchCameras({ commit }, projectId) {
    return cameraService.getCameras(projectId).then((res) => {
      commit('SET_CAMERAS', res.data.data);
      return res.data.data;
    });
  },

  fetchCameraById({ commit }, cameraId) {
    return cameraService.getCameraById(cameraId).then((res) => {
      commit('SET_CURRENT_CAMERA', res.data.data);
      return res.data.data;
    });
  },

  createCamera({ commit }, { projectId, data }) {
    return cameraService.createCamera(projectId, data).then((res) => {
      commit('ADD_CAMERA', res.data.data);
      return res.data.data;
    });
  },

  updateCamera({ commit }, { cameraId, data }) {
    return cameraService.updateCamera(cameraId, data).then((res) => {
      commit('SET_CURRENT_CAMERA', res.data.data);
      return res.data.data;
    });
  },

  deleteCamera(context, cameraId) {
    return cameraService.deleteCamera(cameraId).then((res) => {
      return res.data.data;
    });
  },
};
