import adminService from '@/services/admin';

// ----------------------------------------------------------------------------
// OPTIONS
// ----------------------------------------------------------------------------
export const namespaced = true;

// ----------------------------------------------------------------------------
// STATE
// ----------------------------------------------------------------------------
export const state = {
  admins: [],
  current: null,
};

// ----------------------------------------------------------------------------
// MUTATIONS
// ----------------------------------------------------------------------------
export const mutations = {
  SET_CURRENT_ADMIN(state, adminData) {
    state.current = adminData;
  },
  SET_ADMINS(state, admins) {
    state.admins = admins;
  },
  ADD_ADMIN(state, admin) {
    state.admins.unshift(admin);
  },
  ENABLE_ADMIN(state) {
    state.current.is_active = true;
  },
  DISABLE_ADMIN(state) {
    state.current.is_active = false;
  },
};

// ----------------------------------------------------------------------------
// ACTIONS
// ----------------------------------------------------------------------------
export const actions = {
  fetchAdmins({ commit }) {
    return adminService.getAdmins().then((response) => {
      commit('SET_ADMINS', response.data.data);
      return response.data.data;
    });
  },

  createAdmin({ commit }, data) {
    return adminService.createAdmin(data).then((response) => {
      commit('ADD_ADMIN', response.data.data);
      return response.data.data;
    });
  },

  updateAdmin(context, { userId, data }) {
    return adminService.updateAdmin(userId, data).then((response) => {
      return response.data.data;
    });
  },

  enableAdmin(context, userId) {
    return adminService.enableAdmin(userId).then((res) => {
      return res.data.data;
    });
  },

  deleteAdmin(context, userId) {
    return adminService.deleteAdmin(userId).then((res) => {
      return res.data.data;
    });
  },
};

// ----------------------------------------------------------------------------
// GETTERS
// ----------------------------------------------------------------------------
