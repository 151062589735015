import apiClient from './api-client';

export default {
  getCameras(projectId) {
    return apiClient.get(`/projects/${projectId}/cameras`);
  },

  createCamera(projectId, data) {
    return apiClient.post(`/projects/${projectId}/cameras`, data);
  },

  updateCamera(cameraId, data) {
    data.append('_method', 'PATCH');
    return apiClient.post(`/cameras/${cameraId}`, data);
  },

  deleteCamera(cameraId) {
    return apiClient.delete(`/cameras/${cameraId}`);
  },

  getCameraById(cameraId) {
    return apiClient.get(`/cameras/${cameraId}`);
  },
};
