<template>
  <div class="bg-white shadow rounded p-6">
    <h3 class="font-semibold text-lg uppercase px-4 sm:px-6">{{ report.title }}</h3>

    <div class="mt-4">
      <dl>
        <div class="bg-gray-50 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">
            Tipo
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ report.type }}
          </dd>
        </div>

        <div class="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">
            Descripción
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ description }}
          </dd>
        </div>

        <div class="bg-gray-50 px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">
            Visible para clientes
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <BaseSwitch :value="report.is_active" @on="handleEnable" @off="handleDisable" />
          </dd>
        </div>
      </dl>
    </div>

    <div class="mt-4" v-if="report.type === 'document'">
      <iframe
        title="report"
        :src="report.content"
        width="640"
        height="360"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
    <div class="mt-4" v-else v-html="report.content"></div>

    <div class="text-center mt-4">
      <button class="text-red-600" type="button" @click="handleDelete">Eliminar</button>
    </div>
  </div>
</template>

<script>
import BaseSwitch from '@/components/BaseSwitch.vue';

export default {
  components: { BaseSwitch },
  name: 'ReportCard',
  props: {
    report: Object,
  },
  data() {
    return {};
  },
  computed: {
    title() {
      return this.report.title ?? 'Sin título';
    },
    description() {
      return this.report.description ?? 'Sin descripción';
    },
  },
  methods: {
    formattedDate(date) {
      return new Date(date).toLocaleDateString(undefined, { month: 'long', year: 'numeric' });
    },
    handleEnable() {
      this.$store.dispatch('project/enableReport', this.report.id);
    },
    handleDisable() {
      this.$store.dispatch('project/disableReport', this.report.id);
    },
    handleDelete() {
      this.$emit('delete');
    },
  },
};
</script>

<style>
iframe {
  @apply w-full;
}
</style>
