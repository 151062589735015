import userService from '@/services/user';

// ----------------------------------------------------------------------------
// OPTIONS
// ----------------------------------------------------------------------------
export const namespaced = true;

// ----------------------------------------------------------------------------
// STATE
// ----------------------------------------------------------------------------
export const state = {
  user: null,
};

// ----------------------------------------------------------------------------
// MUTATIONS
// ----------------------------------------------------------------------------
export const mutations = {
  SET_USER_DATA(state, userData) {
    state.user = userData;
    localStorage.setItem('user', JSON.stringify(userData));
  },
};

// ----------------------------------------------------------------------------
// ACTIONS
// ----------------------------------------------------------------------------
export const actions = {
  fetchUser({ commit, state }) {
    if (state.user) {
      return state.user;
    }

    return userService.getUser().then((response) => {
      const user = response.data.data;

      commit('SET_USER_DATA', user);
      return user;
    });
  },
};

// ----------------------------------------------------------------------------
// GETTERS
// ----------------------------------------------------------------------------
