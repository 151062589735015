export default class {
  constructor() {
    this.errors = {};
  }

  capture(errors) {
    this.errors = errors;
  }

  has(key) {
    return Object.prototype.hasOwnProperty.call(this.errors, key);
  }

  first(key) {
    return this.has(key) ? this.errors[key][0] : '';
  }

  firstOfArray(key) {
    const keys = Object.keys(this.errors).filter((item) => item.startsWith(key));

    return keys.length > 0 ? this.errors[`${keys[0]}`][0] : '';
  }

  any() {
    return !!Object.keys(this.errors).length;
  }

  clear(key) {
    if (key) {
      delete this.errors[key];
      return;
    }

    this.errors = {};
  }
}
