<template>
  <div>
    <header class="flex items-center justify-between pb-5 border-b border-gray-200">
      <div class="font-bold">{{ reports.length }} reportes registrados</div>
      <div class="flex space-x-4">
        <label>
          Todos <input type="radio" name="typeFilter" value="all" v-model="typeFilter" />
        </label>
        <label>
          Videos <input type="radio" name="typeFilter" value="video" v-model="typeFilter" />
        </label>
        <label>
          Documentos <input type="radio" name="typeFilter" value="document" v-model="typeFilter" />
        </label>
      </div>
      <div class="flex items-center">
        <BaseButton class="h-10 w-40 ml-3" @click="showCreateModal = true">
          Agregar reporte
        </BaseButton>
      </div>
    </header>

    <div class="space-y-8 max-w-xl mx-auto mt-5">
      <ReportCard
        v-for="report in filteredReports"
        :key="report.id"
        :report="report"
        @delete="handleShowDeleteConfirmation(report)"
      />
    </div>

    <ReportCreateModal
      :show="showCreateModal"
      @close="showCreateModal = false"
      :project="current"
    />

    <ReportDeleteConfirmationModal
      :show="showDeleteConfirmation"
      :busy="deletingReport"
      @confirm="handleDeleteReport"
      @close="showDeleteConfirmation = false"
    />
  </div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import BaseButton from '@/components/BaseButton.vue';
import ReportCard from '@/components/ReportCard.vue';
import ReportDeleteConfirmationModal from '@/components/ReportDeleteConfirmationModal.vue';
import ReportCreateModal from '@/components/ReportCreateModal.vue';

export default {
  components: { BaseButton, ReportCard, ReportCreateModal, ReportDeleteConfirmationModal },
  name: 'Reports',
  data() {
    return {
      typeFilter: 'all',
      showDeleteConfirmation: false,
      showCreateModal: false,
      deletingReport: false,
      report: null,
    };
  },
  computed: {
    ...mapState('project', ['current', 'reports']),
    filteredReports() {
      if (this.typeFilter === 'all') {
        return this.reports;
      }

      return this.reports.filter((report) => report.type === this.typeFilter);
    },
  },
  methods: {
    handleShowDeleteConfirmation(report) {
      this.report = report;
      this.showDeleteConfirmation = true;
    },

    async handleDeleteReport() {
      this.deletingReport = true;

      try {
        await this.$store.dispatch('project/deleteReport', this.report.id);
        this.showDeleteConfirmation = false;
        this.report = null;
      } catch (error) {
        console.log(error);
      } finally {
        this.deletingReport = false;
      }
    },
  },

  async beforeRouteEnter(to, from, next) {
    await store.dispatch('project/fetchReports', to.params.project_id);
    next();
  },
};
</script>
