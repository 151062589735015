import apiClient from './api-client';

export default {
  getAdmins() {
    return apiClient.get(`/admins`);
  },

  createAdmin(data) {
    return apiClient.post('/admins', data);
  },

  updateAdmin(userId, data) {
    data.append('_method', 'PATCH');
    return apiClient.post(`/admins/${userId}`, data);
  },

  enableAdmin(userId) {
    const data = new FormData();
    data.append('_method', 'PATCH');

    return apiClient.post(`/admins/${userId}/active`, data);
  },

  deleteAdmin(userId) {
    return apiClient.delete(`/admins/${userId}`);
  },
};
