import apiClient from './api-client';

export default {
  getProjects(tenantId) {
    return apiClient.get(`/tenants/${tenantId}/projects`);
  },

  createProject(tenantId, data) {
    return apiClient.post(`/tenants/${tenantId}/projects`, data);
  },

  updateProject(projectId, data) {
    data.append('_method', 'PATCH');
    return apiClient.post(`/projects/${projectId}`, data);
  },

  deleteProject(projectId) {
    return apiClient.delete(`/projects/${projectId}`);
  },

  enableProject(projectId) {
    return apiClient.post(`/active-projects`, {
      project_id: projectId,
    });
  },

  disableProject(projectId) {
    return apiClient.delete(`/active-projects/${projectId}`);
  },

  getProjectById(projectId) {
    return apiClient.get(`/projects/${projectId}`);
  },

  getProjectMembers(projectId) {
    return apiClient.get(`/projects/${projectId}/members`);
  },

  addMember(projectId, userId) {
    return apiClient.post(`/projects/${projectId}/members`, {
      user_id: userId,
    });
  },

  removeMember(projectId, userId) {
    return apiClient.delete(`/projects/${projectId}/members/${userId}`);
  },

  getReports(projectId) {
    return apiClient.get(`/projects/${projectId}/reports`);
  },

  addReport(projectId, data) {
    return apiClient.post(`/projects/${projectId}/reports`, data);
  },

  enableReport(reportId) {
    return apiClient.post('/active-reports', {
      report_id: reportId,
    });
  },

  disableReport(reportId) {
    return apiClient.delete(`/active-reports/${reportId}`);
  },

  deleteReport(reportId) {
    return apiClient.delete(`/reports/${reportId}`);
  },
};
