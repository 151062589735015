import apiClient from './api-client';

export default {
  getTenants(perPage, page = 1, search = null) {
    return apiClient.get(`/tenants`, {
      params: {
        page,
        limit: perPage,
        search,
      },
    });
  },

  getTenant(id) {
    return apiClient.get(`/tenants/${id}`);
  },

  createTenant(data) {
    return apiClient.post('/tenants', data);
  },

  updateTenant(tenantId, data) {
    data.append('_method', 'PATCH');
    return apiClient.post(`/tenants/${tenantId}`, data);
  },

  deleteTenant(tenantId) {
    return apiClient.delete(`/tenants/${tenantId}`);
  },

  enableTenant(tenantId) {
    return apiClient.post('/active-tenants', {
      tenant_id: tenantId,
    });
  },

  disableTenant(tenantId) {
    return apiClient.delete(`/active-tenants/${tenantId}`);
  },

  getTenantUsers(tenantId) {
    return apiClient.get(`/tenants/${tenantId}/users`);
  },

  createTenantUser(tenantId, data) {
    return apiClient.post(`/tenants/${tenantId}/users`, data);
  },

  updateTenantUser(userId, data) {
    data.append('_method', 'PATCH');
    return apiClient.post(`/users/${userId}`, data);
  },

  enableTenantUser(data) {
    return apiClient.post(`/active-users`, data);
  },

  disableTenantUser(userId) {
    return apiClient.delete(`/active-users/${userId}`);
  },

  deleteTenantUser(userId) {
    return apiClient.delete(`/users/${userId}`);
  },
};
