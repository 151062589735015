import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import store from '@/store';
import Home from '@/views/Home.vue';
import NotFound from '@/views/NotFound.vue';
import NetworkIssue from '@/views/NetworkIssue.vue';
import Reports from '@/views/Reports.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword'),
  },
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '/',
        name: 'home',
        props: true,
        component: () => import(/* webpackChunkName: "home" */ '../views/TenantList.vue'),
      },
      {
        path: '/admins',
        name: 'admins',
        props: true,
        component: () => import(/* webpackChunkName: "admin-list" */ '../views/Admins/List.vue'),
      },
    ],
  },
  {
    path: '/tenants/:id',
    component: () => import(/* webpackChunkName: "tenant-home" */ '../views/TenantHome.vue'),
    children: [
      {
        path: '/',
        name: 'tenant-home',
        props: true,
        component: () => import(/* webpackChunkName: "project-list" */ '../views/ProjectList.vue'),
      },
      {
        path: 'users',
        name: 'tenant-users',
        props: true,
        component: () =>
          import(/* webpackChunkName: "tenent-users" */ '../views/Tenants/Users/List'),
      },
      {
        path: 'profile',
        name: 'tenant-profile',
        component: () =>
          import(/* webpackChunkName: "tenant-profile" */ '../views/TenantProfile.vue'),
      },
    ],
  },
  {
    path: '/tenants/:id/project/:project_id',
    props: true,
    component: () => import(/* webpackChunkName: "project" */ '../views/Projects/Home.vue'),
    children: [
      {
        path: '/',
        name: 'project-home',
        props: true,
        component: () =>
          import(/* webpackChunkName: "project-home" */ '../views/Projects/Cameras/Index'),
      },
      {
        path: 'reports',
        name: 'project-reports',
        props: true,
        component: Reports,
      },
      {
        path: 'users',
        name: 'project-users',
        props: true,
        component: () =>
          import(/* webpackChunkName: "project-users" */ '../views/Projects/Users/Index'),
      },
      {
        path: 'information',
        name: 'project-information',
        props: true,
        component: () =>
          import(/* webpackChunkName: "project-information" */ '../views/Projects/Information.vue'),
      },
    ],
  },
  {
    path: '/tenants/:id/project/:project_id',
    props: true,
    component: () =>
      import(/* webpackChunkName: "project-location-home" */ '../views/Projects/LocationHome'),
    children: [
      {
        path: 'location',
        name: 'project-location',
        props: true,
        component: () =>
          import(/* webpackChunkName: "project-location" */ '../views/Projects/Location'),
      },
      {
        path: 'camera/:camera_id/location',
        name: 'project-camera-location',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "project-camera-location" */ '../views/Projects/Cameras/Location'
          ),
      },
    ],
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
  },
  {
    path: '/network-issue',
    name: 'network-issue',
    component: NetworkIssue,
  },
  {
    path: '*',
    redirect: { name: '404' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const openRoutes = ['login', 'forgot-password', 'reset-password'];

// ----------------------------------------------------------------------------
// GLOBAL ROUTE GUARDS
// ----------------------------------------------------------------------------
router.beforeEach(async (to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('token');

  NProgress.start();

  if (openRoutes.includes(to.name)) {
    // if (to.name !== 'login' && !isAuthenticated) {
    //   return next({ name: 'login' });
    // }

    if (openRoutes.includes(to.name) && isAuthenticated) {
      return next({ name: 'home' });
    }

    if (isAuthenticated) {
      await store.dispatch('user/fetchUser');
    }

    next();
  } else if (isAuthenticated) {
    if (isAuthenticated) {
      await store.dispatch('user/fetchUser');
    }

    next();
  } else {
    next({ name: 'login' });
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
