<template>
  <div class="max-w-screen-xl mx-auto fixed bottom-0 right-0 left-0 m-6" style="z-index: 9999999;">
    <Transition
      name="slide-fade"
      v-for="(notification, index) in notification.notifications"
      :key="index"
    >
      <div
        :class="{
          'bg-red-600 text-white': notification.type === 'error',
          'bg-primary text-white': notification.type === 'success',
        }"
        class="shadow-md p-6 pr-10 mb-3"
        style="min-width: 240px"
      >
        <button
          class="opacity-75 cursor-pointer absolute top-0 right-0 py-2 px-3 hover:opacity-100 focus:outline-none"
          @click.stop="hide(notification)"
        >
          X
        </button>
        <div class="flex items-center">
          {{ notification.message }}
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'toast-message',
  computed: {
    ...mapState(['notification']),
  },
  methods: {
    hide(notification) {
      this.$store.commit('notification/DELETE', notification);
    },
  },
};
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.4s;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(400px);
  opacity: 0;
}
</style>
