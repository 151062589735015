<template>
  <div
    class="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center p-4 overflow-hidden"
    style="z-index:9999999;"
    v-if="show"
  >
    <div
      class="bg-white px-9 py-6 rounded-md relative w-full sm:max-w-xl max-h-full overflow-y-auto"
      :class="modalClass"
    >
      <button
        type="button"
        class="absolute top-3 right-3 focus:outline-none focus:ring"
        @click="$emit('close')"
      >
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          ></path>
        </svg>
      </button>

      <header class="border-b border-gray-400 pb-4">
        <slot name="header"></slot>
      </header>

      <section class="mt-4">
        <slot></slot>
      </section>

      <footer>
        <slot name="footer"></slot>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    show: { type: Boolean, default: false },
    modalClass: { type: [String, Object], default: null },
  },
};
</script>
