import projectService from '@/services/project';

// ----------------------------------------------------------------------------
// OPTIONS
// ----------------------------------------------------------------------------
export const namespaced = true;

// ----------------------------------------------------------------------------
// STATE
// ----------------------------------------------------------------------------
export const state = {
  projects: [],
  projectsTotal: 0,
  current: null,
  perPage: 15,
  meta: {},
  members: [],
  reports: [],
};

// ----------------------------------------------------------------------------
// MUTATIONS
// ----------------------------------------------------------------------------
export const mutations = {
  SET_CURRENT_PROJECT(state, projectData) {
    state.current = projectData;
  },
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },
  SET_PROJECTS_TOTAL(state, total) {
    state.projectsTotal = total;
  },
  ADD_PROJECT(state, project) {
    state.projects.unshift(project);
  },
  ENABLE_PROJECT(state) {
    state.current.is_active = true;
  },
  DISABLE_PROJECT(state) {
    state.current.is_active = false;
  },
  SET_PROJECT_MEMBERS(state, members) {
    state.members = members;
  },
  SET_REPORTS(state, reports) {
    state.reports = reports;
  },
  ADD_REPORT(state, report) {
    state.reports.unshift(report);
  },
  DISABLE_REPORT(state, reportId) {
    const reportIndex = state.reports.findIndex((report) => report.id === reportId);
    const report = state.reports[reportIndex];
    report.is_active = false;
  },
  ENABLE_REPORT(state, reportId) {
    const reportIndex = state.reports.findIndex((report) => report.id === reportId);
    const report = state.reports[reportIndex];
    report.is_active = true;
  },
};

// ----------------------------------------------------------------------------
// ACTIONS
// ----------------------------------------------------------------------------
export const actions = {
  fetchProjects({ commit }, tenantId) {
    return projectService.getProjects(tenantId).then((response) => {
      commit('SET_PROJECTS', response.data.data);
      commit('SET_PROJECTS_TOTAL', parseInt(response.headers['x-total-count'], 10));
      return response.data.data;
    });
  },

  fetchProjectById({ commit }, projectId) {
    return projectService.getProjectById(projectId).then((res) => {
      commit('SET_CURRENT_PROJECT', res.data.data);
      return res.data.data;
    });
  },

  createProject({ commit }, { tenantId, data }) {
    return projectService.createProject(tenantId, data).then((response) => {
      commit('ADD_PROJECT', response.data.data);
      return response.data.data;
    });
  },

  updateProject({ commit }, { projectId, data }) {
    return projectService.updateProject(projectId, data).then((res) => {
      commit('SET_CURRENT_PROJECT', res.data.data);
      return res.data.data;
    });
  },

  deleteProject(context, projectId) {
    return projectService.deleteProject(projectId).then((res) => {
      return res.data.data;
    });
  },

  enableProject(context, projectId) {
    return projectService.enableProject(projectId).then((res) => {
      return res.data.data;
    });
  },

  disableProject(context, projectId) {
    return projectService.disableProject(projectId).then((res) => {
      return res.data.data;
    });
  },

  getMembers({ commit }, projectId) {
    return projectService.getProjectMembers(projectId).then((res) => {
      commit('SET_PROJECT_MEMBERS', res.data.data);
      return res.data.data;
    });
  },

  addMember(context, { projectId, userId }) {
    return projectService.addMember(projectId, userId).then((res) => {
      return res.data.data;
    });
  },

  removeMember(context, { projectId, userId }) {
    return projectService.removeMember(projectId, userId).then((res) => {
      return res.data.data;
    });
  },

  fetchReports({ commit }, projectId) {
    return projectService.getReports(projectId).then((response) => {
      commit('SET_REPORTS', response.data.data);
      return response.data.data;
    });
  },

  addReport({ commit, dispatch }, { projectId, data }) {
    return projectService
      .addReport(projectId, data)
      .then((response) => {
        commit('ADD_REPORT', response.data.data);
        return response.data.data;
      })
      .catch((error) => {
        dispatch('notification/addError', error.response.data.message, { root: true });
        throw error;
      });
  },

  disableReport({ commit, dispatch }, reportId) {
    return projectService
      .disableReport(reportId)
      .then((response) => {
        commit('DISABLE_REPORT', reportId);

        return response.data.data;
      })
      .catch((error) => {
        const message = 'Ocurrió un error al desactivar el reporte.';
        dispatch('notification/addError', message, { root: true });
        throw error;
      });
  },

  enableReport({ commit, dispatch }, reportId) {
    return projectService
      .enableReport(reportId)
      .then((response) => {
        commit('ENABLE_REPORT', reportId);

        return response.data.data;
      })
      .catch((error) => {
        const message = 'Ocurrió un error al activar el reporte.';
        dispatch('notification/addError', message, { root: true });
        throw error;
      });
  },

  deleteReport({ state, commit, dispatch }, reportId) {
    return projectService
      .deleteReport(reportId)
      .then((response) => {
        const reports = state.reports.filter((report) => report.id != reportId);
        commit('SET_REPORTS', reports);

        return response;
      })
      .catch((error) => {
        const message = 'Ocurrió un error al eliminar el reporte.';
        dispatch('notification/addError', message, { root: true });
        throw error;
      });
  },
};
