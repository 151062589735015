<template>
  <button
    type="button"
    class="relative rounded-full focus:outline-none focus:ring"
    @click="handleSwitch"
  >
    <div
      class="h-6 w-6 bg-white absolute rounded-full border transform-gpu transition-transform duration-300"
      :class="{ 'translate-x-full': value }"
    ></div>
    <div
      class="h-6 w-12 rounded-full transition-colors delay-150"
      :class="{ 'bg-lime-500': value, 'bg-gray-300': !value }"
    ></div>
  </button>
</template>

<script>
export default {
  name: 'BaseSwitch',
  props: {
    value: { 
      type: [Boolean, Number],
      default: false 
    },
  },
  methods: {
    handleSwitch() {
      console.log("🚀 ~ file: BaseSwitch.vue:29 value:", this.value)
      
      if (this.value) {
        this.$emit('off');
      } else {
        this.$emit('on');
      }

      this.$emit('input', !this.value);
    },
  },
};
</script>
