import authService from '@/services/auth';
import apiClient from '@/services/api-client';

// ----------------------------------------------------------------------------
// OPTIONS
// ----------------------------------------------------------------------------
export const namespaced = true;

// ----------------------------------------------------------------------------
// STATE
// ----------------------------------------------------------------------------
export const state = {
  token: null,
};

// ----------------------------------------------------------------------------
// MUTATIONS
// ----------------------------------------------------------------------------
export const mutations = {
  SET_AUTH_DATA(state, token) {
    localStorage.setItem('token', token);
    apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },
  CLEAR_AUTH_DATA() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    location.reload();
  },
};

// ----------------------------------------------------------------------------
// ACTIONS
// ----------------------------------------------------------------------------
export const actions = {
  login({ commit }, credentials) {
    return authService.login(credentials).then((token) => {
      commit('SET_AUTH_DATA', token);
      return token;
    });
  },

  logout({ commit }) {
    return authService.logout().then(() => {
      commit('CLEAR_AUTH_DATA');
    });
  },

  forgotPassword(context, data) {
    return authService.forgotPassword(data).then((res) => {
      return res.data.data;
    });
  },

  resetPassword(context, data) {
    return authService.resetPassword(data).then((res) => {
      return res.data.data;
    });
  },
};

// ----------------------------------------------------------------------------
// GETTERS
// ----------------------------------------------------------------------------
