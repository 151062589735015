<template>
  <div>
    <BaseModal :show="show" @close="$emit('close')">
      <h3 slot="header" class="font-bold text-base">Agregar reporte</h3>

      <form method="post" @submit.prevent="handleSubmit" ref="formRef">
        <div class="space-y-6">
          <div class="flex space-x-4">
            <label> Video <input type="radio" name="type" value="video" v-model="type" /> </label>
            <label>
              Documento <input type="radio" name="type" value="document" v-model="type" />
            </label>
          </div>

          <!-- Title -->
          <div>
            <BaseInput
              label="Título"
              name="title"
              placeholder="Título del reporte"
              :error="errors.first('title')"
              v-model="title"
            />
          </div>

          <!-- Description -->
          <div>
            <BaseInput
              label="Descripción"
              name="description"
              type="textarea"
              rows="3"
              placeholder="Descripción del reporte"
              :error="errors.first('description')"
              v-model="description"
            />
          </div>

          <!-- Date -->
          <div>
            <datetime
              required
              name="date"
              format="YYYY-MM-DD"
              :error="errors.first('date')"
              v-model="date"
            />
          </div>

          <!-- Content -->
          <div>
            <BaseInput
              label="Contenido"
              name="content"
              type="textarea"
              rows="5"
              required
              placeholder="Contenido"
              :error="errors.first('content')"
              v-model="content"
              v-if="type === 'video'"
            />

            <BaseFileInput v-else accept="application/pdf" label="Archivo" name="document" />
          </div>
        </div>

        <div class="mt-6 flex items-stretch justify-between space-x-3">
          <BaseButton class="flex-grow py-3" secondary @click="$emit('close')">
            Cancelar
          </BaseButton>
          <BaseButton
            class="flex-grow py-3 inline-flex items-center justify-center"
            type="submit"
            :disabled="loading"
          >
            <svg
              v-if="loading"
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path></svg
            >Agregar
          </BaseButton>
        </div>
      </form>
    </BaseModal>
  </div>
</template>

<script>
import Errors from '@/util/Errors';
import dateUtils from '@/util/date-utils';
import datetime from 'vuejs-datetimepicker';
import BaseButton from '@/components/BaseButton';
import BaseModal from '@/components/BaseModal';
import BaseInput from '@/components/BaseInput';
import BaseFileInput from '@/components/BaseFileInput.vue';

export default {
  name: 'ReportCreateModal',
  components: { BaseButton, BaseFileInput, BaseInput, BaseModal, datetime },
  props: {
    show: { type: Boolean, default: false },
    project: { type: Object, required: true },
  },
  data() {
    return {
      title: '',
      date: dateUtils.formatYMD(new Date()),
      description: '',
      type: 'video',
      content: '',
      loading: false,
      errors: new Errors(),
    };
  },
  methods: {
    async handleSubmit(event) {
      // const { title, description, date, content, type } = Object.fromEntries(
      //   new FormData(event.target),
      // );
      const formData = new FormData(event.target);
      formData.set('date', new Date(formData.get('date')).toISOString());

      try {
        this.loading = true;
        await this.$store.dispatch('project/addReport', {
          projectId: this.project.id,
          data: formData,
        });

        this.resetForm();
        this.$emit('close');
      } catch (error) {
        console.log(error.response?.data?.message ?? error.message);
      } finally {
        this.loading = false;
      }
    },

    resetForm() {
      this.title = '';
      this.description = '';
      this.type = 'video';
      this.content = '';
      this.date = dateUtils.formatYMD(new Date());
      this.$refs.formRef.reset();
    },
  },
};
</script>
