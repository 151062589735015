import apiClient from './api-client';

export default {
  getPhotos(cameraId, startDate, endDate, selection, perPage, page) {
    return apiClient.get(`/cameras/${cameraId}/photos`, {
      params: {
        start_date: startDate.toISOString(),
        end_date: endDate.toISOString(),
        selection_strategy: selection,
        limit: perPage,
        page: page,
      },
    });
  },

  downloadPhotos(cameraId, { startDate, endDate, photoId }) {
    return apiClient.get(`/cameras/${cameraId}/photos/download`, {
      params: {
        photo_id: photoId,
        start_date: startDate ? startDate.toISOString() : null,
        end_date: endDate ? endDate.toISOString() : null,
      },
      responseType: 'blob',
    });
  },

  sendPhoto(photoId, subject, recipients) {
    return apiClient.post(`/photos/${photoId}/send`, {
      subject,
      recipients,
    });
  },

  deletePhoto(photoId) {
    return apiClient.delete(`/photos/${photoId}`);
  },
};
