<template>
  <header class="flex items-center justify-between">
    <h1 class="font-bold uppercase">
      <router-link :to="{ name: 'home' }">
        <img src="/img/timelapse-logo.png" class="inline-block w-32" />
      </router-link>
    </h1>

    <nav>
      <ul class="flex items-center space-x-4">
        <li>
          <router-link
            :to="{ name: 'home' }"
            class="text-center w-36 block"
            :class="{
              'bg-primary text-white py-1 rounded-full font-semibold': companyLinkIsActive,
            }"
          >
            Empresas
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'admins' }"
            class="text-center w-36 block"
            exact-active-class="bg-primary text-white py-1 rounded-full font-semibold"
          >
            Administradores
          </router-link>
        </li>
      </ul>
    </nav>

    <div class="relative inline-block text-left">
      <div class="relative">
        <button
          class="flex items-center outline-none focus:outline-none"
          id="options-menu"
          aria-expanded="true"
          aria-haspopup="true"
          @click="isOpen = !isOpen"
        >
          <span>{{ user.user.email }}</span>
          <i class="tmlps-i-arrow-down text-2xl"></i>
        </button>

        <div
          v-if="isOpen"
          class="top-8 w-60 p-1 shadow-xl absolute bg-white border border-gray-300"
          role="menu"
        >
          <button
            @click="handlerLogout"
            class="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          >
            Salir
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Header',
  computed: {
    ...mapState(['user']),
    companyLinkIsActive() {
      return this.$route.name !== 'admins';
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    handlerLogout() {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>
